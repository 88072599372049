import React from 'react'
import { Link } from 'gatsby'
import moment from 'moment'
import Disqus from '../Disqus/Disqus'
import './style.scss'
import Sidebar from '../Sidebar'

class PostTemplateDetails extends React.Component {
  render() {
    const { subtitle, author } = this.props.data.site.siteMetadata
    const post = this.props.data.markdownRemark
    const tags = post.fields.tagSlugs
    const category = post.fields.categorySlug

    const metaBlock = (
      <div className="post-single__meta">
        <time
          className="post-single__meta-time"
          dateTime={moment(post.frontmatter.date).format('MMMM D, YYYY')}
        >
          {moment(post.frontmatter.date).format('MMMM YYYY')}
        </time>
        <span className="post-single__meta-divider" />
        <span className="post-single__meta-category" key={category}>
          <Link className="post-single__meta-category-link" to={category}>
            {post.frontmatter.category}
          </Link>
        </span>
      </div>
    )

    const tagsBlock = (
      <div className="post-single__tags">
        <ul className="post-single__tags-list">
          {tags &&
            tags.map((tag, i) => (
              <li className="post-single__tags-list-item" key={tag}>
                <Link to={tag} className="post-single__tags-list-item-link">
                  {post.frontmatter.tags[i]}
                </Link>
              </li>
            ))}
        </ul>
      </div>
    )

    const commentsBlock = (
      <div>
        <Disqus
          postNode={post}
          siteMetadata={this.props.data.site.siteMetadata}
        />
      </div>
    )

    return (
      <div>
        <Sidebar {...this.props} />
        <div className="content">
          <div className="content__inner">
            <div className="page">
              <div className="post-single">
                <div className="post-single__inner">
                  {metaBlock}
                  <h1 className="post-single__title">{post.frontmatter.title}</h1>
                  <div
                    className="post-single__body"
                    /* eslint-disable-next-line react/no-danger */
                    dangerouslySetInnerHTML={{ __html: post.html }}
                  />
                  <div className="post-single__date">
                    <em>
                      Published {moment(post.frontmatter.date).format('D MMM YYYY')}
                    </em>
                  </div>
                </div>
                <div className="post-single__footer">
                  {tagsBlock}
                  <hr />
                  <p className="post-single__footer-text">
                    {subtitle}
                  </p>
                  {commentsBlock}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PostTemplateDetails
